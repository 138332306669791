<template>
  <div>
    <car-master-maintainance-popup-branch
      :temp-search-branch="tempSearchBranch"
      :is-popup-branch-active.sync="isPopupBranchActive"
      @select-item="fncSelectBranch"
    />
    <car-master-maintainance-popup-reg-num
      :temp-search-reg-num="tempSearchRegNum"
      :is-popup-reg-num-active.sync="isPopupRegNumActive"
      @select-item="fncSelectRegNum"
    />
    <template v-if="$can('update', 'CarMasterMaintainance')">
      <b-button
        variant="primary"
        :to="{ name: 'car-master-maintainance-form-add' }"
      >
        เพิ่มข้อมูล
      </b-button>
    </template>
    <b-card
      title="ข้อมูลหลักรถยนต์"
      class="mt-1"
    >
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="branchCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                พอร์ต
              </div>
            </template>
            <b-input-group v-show="!branchCode">
              <b-form-input
                id="branchCode"
                v-model="inputSearchBranch"
                placeholder="ค้นหาพอร์ต"
                trim
                @keydown.enter.native="
                  fncSearchBranch(inputSearchBranch)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupBranch()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="branchCode">
              <b-form-input
                id="branchCode"
                :value="branchName"
                trim
                disabled
              />
              <b-input-group-append v-if="!isDefaultBranchFromLogin">
                <b-button
                  variant="primary"
                  @click="fncCancelBranch()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="inputSearchRegNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ค้นหาเลขทะเบียน <span class="text-danger">*</span>
              </div>
            </template>
            <b-input-group v-show="!regNum1 && !regNum2">
              <b-form-input
                id="inputSearchRegNum"
                v-model="inputSearchRegNum1"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;"
                @keydown.enter.native="refInputSearchRegNum2.focus()"
              />
              <b-form-input
                id="inputSearchRegNum2"
                ref="refInputSearchRegNum2"
                v-model="inputSearchRegNum2"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;  margin-left: 5px;"
                @keydown.enter.native="fncSearcRegNum(inputSearchRegNum1, inputSearchRegNum2)"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupRegNum()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="regNum1 || regNum2">
              <b-form-input
                id="inputSearchRegNum"
                v-model="regNum1"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;"
                disabled
              />
              <b-form-input
                id="inputSearchRegNu2"
                v-model="regNum2"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%; margin-left: 5px;"
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelRegNum()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="หมายเลขตัวถัง"
            label-for="chassisNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                หมายเลขตัวถัง
              </div>
            </template>
            <b-form-input
              id="chassisNum"
              v-model="InputchassisNum"
              placeholder="หมายเลขตัวถัง"
              trim
              autocomplete="off"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="3"
            label-cols-sm="12"
          >
            <b-overlay
              :show="overlaySubmitButton"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button
                variant="primary"
                @click="fncSubmit()"
              >
                ยืนยัน
              </b-button>
            </b-overlay>
            <b-button
              class="ml-1"
              variant="outline-secondary"
              @click="fncResetForm()"
            >
              ยกเลิก
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <car-master-maintainance-result
      :data-result="dataResult"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BButton, BOverlay,
} from 'bootstrap-vue'
import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import CarMasterMaintainancePopupBranch from './car-master-maintainance-popup/CarMasterMaintainancePopupBranch.vue'
import CarMasterMaintainancePopupRegNum from './car-master-maintainance-popup/CarMasterMaintainancePopupRegNum.vue'
import CarMasterMaintainanceResult from './CarMasterMaintainanceResult.vue'
import CarMasterMaintainanceStoreModule from './CarMasterMaintainanceStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BOverlay,
    CarMasterMaintainancePopupBranch,
    CarMasterMaintainancePopupRegNum,
    CarMasterMaintainanceResult,
  },

  setup() {
    const APP_STORE_MODULE_NAME = 'appCarMasterMaintainance'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, CarMasterMaintainanceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // ประกาศตัวแปร Textbox
    const inputSearchBranch = ref(null)
    const inputSearchRegNum1 = ref(null)
    const inputSearchRegNum2 = ref(null)
    const InputchassisNum = ref(null)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const username = ref(null)
    const cpncod = ref(null)
    const branchCode = ref(null)
    const branchName = ref(null)
    const regNum1 = ref(null)
    const regNum2 = ref(null)
    const tempSearchBranch = ref(null)
    const tempSearchRegNum = ref(null)
    const dataResult = ref([])
    const isDefaultBranchFromLogin = ref(false)

    // ประกาศตัวแปร Active / Inactive
    const isPopupBranchActive = ref(false)
    const isPopupRegNumActive = ref(false)

    // ประกาศตัวแปรแสดงสถานะ Loading
    const overlaySubmitButton = ref(false)

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* ฟังก์ชั่น Work Scope */
    const fncFetchWorkScope = () => {
      const payload = {
        action: 'VIEW',
        events: 'SIGNIN',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_USER_WORK_SCOPE`, payload)
        .then(response => {
          /* eslint-disable no-console */
          username.value = response.data.responseData[0].username
          cpncod.value = response.data.responseData[0].cpncod

          if (response.data.responseData[0].cpnbrncod) {
            branchCode.value = response.data.responseData[0].cpnbrncod
            branchName.value = response.data.responseData[0].tabdsctha
            isDefaultBranchFromLogin.value = true
          }
        })
        .catch(error => {
          /* eslint-disable no-console */
          console.log(error)
        })
    }

    /* ฟังก์ชั่นเปิด Popup พอร์ต */
    const fncOpenPopupBranch = search => {
      if (search) tempSearchBranch.value = search
      else tempSearchBranch.value = null

      isPopupBranchActive.value = true
    }

    /* ฟังก์ชั่นค้นหาพอร์ต */
    const fncSearchBranch = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CPNBRNCOD',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              branchCode.value = getSearchResult[0].tabkeytwo
              branchName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupBranch(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกพอร์ต */
    const fncCancelBranch = () => {
      branchCode.value = null
      branchName.value = null
      inputSearchBranch.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกพอร์ตจาก Popup */
    const fncSelectBranch = data => {
      branchCode.value = data.tabkeytwo
      branchName.value = data.tabdsctha
    }

    /* ฟังก์ชั่นเปิด Popup เลขทะเบียน */
    const fncOpenPopupRegNum = search => {
      if (search) tempSearchRegNum.value = search
      else tempSearchRegNum.value = null

      isPopupRegNumActive.value = true
    }

    /* ฟังก์ชั่นค้นหาเลขทะเบียน */
    const fncSearcRegNum = (val1, val2) => {
      const payload = {
        action: 'LIST',
        events: 'CONTRACT',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        TEXT_SEARCH: '',
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_GET_CAR_REG`, payload)
        .then(response => {
          const getSearchResult = response.data.responseData.filter(
            item => item.regnum1.toLowerCase().indexOf(val1) > -1 && item.regnum2.toLowerCase().indexOf(val2) > -1,
          )

          if (getSearchResult.length === 1) {
            regNum1.value = getSearchResult[0].regnum1
            regNum2.value = getSearchResult[0].regnum2
          } else if ((getSearchResult.length > 1)) {
            fncOpenPopupRegNum(`${val1}-${val2}`)
          }
        })
    }

    /* ฟังก์ชั่นยกเลิกเลขทะเบียน */
    const fncCancelRegNum = () => {
      regNum1.value = null
      regNum2.value = null
      inputSearchRegNum1.value = null
      inputSearchRegNum2.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกเลขทะเบียนจาก Popup */
    const fncSelectRegNum = data => {
      regNum1.value = data.regnum1
      regNum2.value = data.regnum2
    }

    /* ฟังก์ชั่นเมื่อกดปุ่มยืนยัน */
    const fncSubmit = () => {
      overlaySubmitButton.value = true
      setInterval(() => { overlaySubmitButton.value = false }, 5000)

      const payload = {
        action: 'LIST',
        events: 'MAINTENANCE',
        function: 'GET',
        USERNAME: username.value ? username.value : JSON.parse(localStorage.getItem('userData')).member_no,
        CPNCOD_IN: '0001',
        CPNBRNCOD_IN: branchCode.value,
        REGNUM1_IN: regNum1.value,
        REGNUM2_IN: regNum2.value,
        CHASSIS_NUMBER_IN: InputchassisNum.value,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_GET_CAR_MASTER_LIST`, payload)
        .then(response => {
          if (response.data.responseData.length) {
            dataResult.value = response.data.responseData
          } else {
            console.log('ไม่พบข้อมูลที่ค้นหา')
          }
        })
    }

    /* ฟังก์ชั่นเมื่อกดปุ่มยกเลิก จะทำการ reset ข้อมูล */
    const fncResetForm = () => {
      inputSearchBranch.value = null
      inputSearchRegNum1.value = null
      inputSearchRegNum2.value = null
      InputchassisNum.value = null

      branchCode.value = null
      branchName.value = null
      regNum1.value = null
      regNum2.value = null
      tempSearchBranch.value = null
      tempSearchRegNum.value = null
      dataResult.value = []
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    /* Life Cycle Onmunted */
    onMounted(() => {
      fncFetchWorkScope()
    })

    return {
      // ประกาศตัวแปร Textbox
      inputSearchBranch,
      inputSearchRegNum1,
      inputSearchRegNum2,
      InputchassisNum,

      // ประกาศตัวแปรสำหรับเก็บค่า
      username,
      cpncod,
      branchCode,
      branchName,
      regNum1,
      regNum2,
      tempSearchBranch,
      tempSearchRegNum,
      dataResult,
      isDefaultBranchFromLogin,

      // ประกาศตัวแปร Active / Inactive
      isPopupBranchActive,
      isPopupRegNumActive,

      // ประกาศตัวแปรแสดงสถานะ Loading
      overlaySubmitButton,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncOpenPopupBranch,
      fncSearchBranch,
      fncCancelBranch,
      fncSelectBranch,
      fncOpenPopupRegNum,
      fncSearcRegNum,
      fncCancelRegNum,
      fncSelectRegNum,
      fncSubmit,
      fncResetForm,
    }
  },
}
</script>

<style>
/* for local only */
/* .delete-margin {
  margin-bottom: -60px !important;
} */
</style>

<template>
  <b-card title="ตารางข้อมูล">
    <div>
      <b-table-simple
        caption-top
        bordered
        class="position-relative mb-2"
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th class="text-center">
              แก้ไข
            </b-th>
            <b-th class="text-center">
              ยกเลิก
            </b-th>
            <b-th class="text-center">
              พอร์ต
            </b-th>
            <b-th class="text-center">
              ทะเบียน
            </b-th>
            <b-th class="text-center">
              เลขตัวถัง
            </b-th>
            <b-th class="text-center">
              เลขเครื่องยนต์
            </b-th>
            <b-th class="text-center">
              รุ่น
            </b-th>
            <b-th class="text-center">
              เชื้อเพลิง
            </b-th>
            <b-th class="text-center">
              สหกรณ์
            </b-th>
            <b-th class="text-center">
              สถานที่เก็บ
            </b-th>
            <b-th class="text-center">
              สถานะ
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-if="dataResult.length">
            <b-tr
              v-for="(item, index) in paginateArray(
                dataResult,
                perPage,
                currentPage
              )"
              :key="index"
            >
              <b-td class="text-center">
                <b-button
                  variant="gradient-primary"
                  class="btn-icon rounded-circle"
                  @click="fncEditItem(item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </b-td>
              <b-td class="text-center">
                <b-button
                  variant="gradient-danger"
                  class="btn-icon rounded-circle"
                  @click="fncRemoveItem(item)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </b-td>
              <b-td class="text-center">
                {{ `${item.cpnbrncod} ${item.port_desc}` }}
              </b-td>
              <b-td class="text-center">
                {{ `${item.regnum1}-${item.regnum2}` }}
              </b-td>
              <b-td class="text-center">
                {{ item.chassis_number }}
              </b-td>
              <b-td class="text-center">
                {{ item.engin_number }}
              </b-td>
              <b-td class="text-center">
                {{ item.car_model }}
              </b-td>
              <b-td class="text-center">
                {{ item.car_energy }}
              </b-td>
              <b-td class="text-center">
                {{ item.cooperate_code }}
              </b-td>
              <b-td class="text-center">
                {{ item.car_location }}
              </b-td>
              <b-td class="text-center">
                {{ item.status_desc }}
              </b-td>
            </b-tr>
          </template>
          <template v-else>
            <b-tr>
              <b-td
                colspan="11"
                class="text-center"
              >
                <p style="margin-top:5px; margin-bottom:5px;">
                  ไม่พบรายการที่ตรงกัน
                </p>
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
      <b-row>
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataResult.length"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BTableSimple, BThead, BTr, BTh, BTbody, BTd, BRow, BCol, BPagination, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'

export default {
  components: {
    BCard,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BRow,
    BCol,
    BPagination,
    BButton,
  },

  props: {
    dataResult: {
      type: [Object, Array],
      required: true,
    },
  },

  setup() {
    // ประกาศตัวแปรสำหรับเก็บค่า
    const perPage = ref(25)
    const currentPage = ref(1)

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* เมื่อกดปุ่มแก้ไขข้อมูลที่รายการ */
    const fncEditItem = data => {
      router.push({ name: 'car-master-maintainance-form-edit', params: { branch_code: data.cpnbrncod, chassis_number: data.chassis_number } })
    }

    /* เมื่อกดปุ่มลบรายการ */
    const fncRemoveItem = data => {
      router.push({ name: 'car-master-maintainance-form-remove', params: { branch_code: data.cpnbrncod, chassis_number: data.chassis_number } })
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    /* ========== ส่วนการทำงานแสดงผล UI ========== */
    /* คำสั่งแบ่งหน้า */
    const paginateArray = (array, getPerPage, page) => array.slice((page - 1) * getPerPage, page * getPerPage)
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    return {
      // ประกาศตัวแปรสำหรับเก็บค่า
      perPage,
      currentPage,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncEditItem,
      fncRemoveItem,

      // ส่วนการทำงานแสดงผล UI
      paginateArray,
    }
  },
}
</script>

<style>

</style>
